@use './utils/styles/publicStyleVariables' as com;

.pub-home-main {
    overflow-x: hidden;

    .header-encloser {
        position: relative;
        width: 100%;
    }

    .footer-encloser {
        position: relative;
        width: 100%;
    }

    .main-head-wrapper {
        z-index: 1;
        min-height: 500px;
        height: 500px;
        position: relative;        
        
        background-blend-mode: overlay;
        background-size: cover !important;
        // background-position: bottom;

        // background-color: com.$blue-1;
        .side-angle {
            position: absolute;
            height: 90%;
            width: 112px;
            top: 16px;
            
            background-size: cover !important;
            background-position: right !important;

        }

        .head-laptop {
            position: absolute;
            height: 80%;
            max-height: 380px;
            min-width: 490px;
            bottom: -10px;
            right: 10%;
            
            background-size: contain !important;
            background-position: right !important;

            .lap-wrap {
                position: relative;
                width: 100%;
                height: 100%;

                .laptop-arcs-1 {
                    position: absolute;
                    height: 90%;
                    width: 40%;
                    right: 70%;

                    svg {
                        height: 100%;
                        width: 100%;
                    }

                    circle {
                        fill: transparent;
                        stroke: com.$white-1;
                        opacity: 0.1;
                        stroke-width: 2;
                    }

                    .dashed {
                        stroke-dasharray: 8, 8.5;
                    }
                }

                .laptop-arcs-2 {
                    position: absolute;
                    height: 62%;
                    width: 100%;
                    left: 1%;
                    top: -10%;

                    svg {
                        height: 100%;
                        width: 100%;
                    }

                    circle {
                        fill: transparent;
                        stroke: com.$white-1;
                        opacity: 0.1;
                        stroke-width: 2;
                        transform: rotate(-8deg);
                    }

                    .dashed {
                        stroke-dasharray: 8, 8.5;
                    }
                }

                .laptop-arcs-3 {
                    position: absolute;
                    height: 25%;
                    width: 25%;
                    right: -11%;
                    bottom: 36%;

                    svg {
                        height: 100%;
                        width: 100%;
                    }

                    circle {
                        fill: transparent;
                        stroke: com.$white-1;
                        opacity: 0.1;
                        stroke-width: 2;
                        // transform: rotate(-8deg);
                    }

                    .dashed {
                        stroke-dasharray: 8, 8.5;
                    }
                }
            }

        }

        .title-content {
            display: flex;
            align-items: center;
            // justify-content: left;
            height: 100%;
            width: 44%;

            .title-enc {
                // height: 100%;
                // align-self: center;
                padding-left: 3rem;
                padding-top: 1rem;
            }

            .main-h-1 {
                color: com.$white-1;
                width: 88%;
                font-size: 24px;
                font-weight: 400;
            }

            .sub-h-3 {
                color: com.$white-1;
                font-weight: 400;
                line-height: 18px;
            }
        }
    }

    .main-work-demo-wrapper {
        .main-h-2 {
            color: com.$blue-1;
            padding-top: 1rem;
        }

        .how-works {
            padding-left: 3rem;

            .main-h-2 {
                padding-bottom: 1rem;
                padding-top: 1.5rem;

            }

            .img-data-binder {
                padding-left: 8px;

                .data-div {
                    .head {
                        font-size: 16px;
                        color: com.$blue-1;
                        padding-bottom: 6px;
                        align-items: center;
                    }

                    .data {
                        font-size: 14px;
                        color: com.$black-2;
                    }
                }

                .img-div {
                    display: flex;
                    justify-content: end;
                    padding-right: 2rem;
                    align-items: center;

                    .out-cir {
                        width: 260px;
                        height: 260px;
                        position: relative;

                    }

                    .inn-cir {
                        position: relative;
                        width: 260px;
                        height: 260px;
                        background-color: #ccd3da;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &::before {
                            content: "";
                            position: absolute;
                            width: 220px;
                            height: 220px;
                            border-radius: 50%;
                            background-color: com.$blue-1;
                        }

                        .logo-img {
                            z-index: 1
                        }

                    }

                    .arc {
                        position: absolute;
                        width: calc(100% + 42px);
                        height: calc(100% + 42px);
                        left: -22px;
                        bottom: -22px;
                        border: 2px solid com.$blue-1;
                        border-radius: 50%;
                        border-right-color: transparent;
                        border-bottom-color: transparent;
                        -webkit-transform: rotate(-45deg);
                        transform: rotate(-45deg);

                        .arc-in {
                            position: relative;
                            width: 100%;
                            height: 100%;

                            .dot-1 {
                                position: absolute;
                                width: 14px;
                                height: 14px;
                                border-radius: 50%;
                                background-color: com.$blue-1;
                                left: calc(50% + 38px);

                            }

                            .dot-2 {
                                position: absolute;
                                width: 14px;
                                height: 14px;
                                border-radius: 50%;
                                background-color: com.$blue-1;
                                // left: calc(50% + 38px);
                                left: 36px;
                                top: 36px;

                            }

                            .dot-3 {
                                position: absolute;
                                width: 14px;
                                height: 14px;
                                border-radius: 50%;
                                background-color: com.$blue-1;
                                top: calc(50% + 38px);
                            }

                            .dot-in {
                                position: relative;
                                width: 100%;
                                height: 100%;

                                .dash-1,
                                .dash-3 {
                                    position: absolute;
                                    width: 154px;
                                    left: -130px;
                                    bottom: 66px;
                                    border-top: 1px dashed com.$black-1;
                                    transform: rotate(45deg);
                                }

                                .dash-2 {
                                    position: absolute;
                                    width: 72px;
                                    left: -58px;
                                    bottom: 36px;
                                    border-top: 1px dashed com.$black-1;
                                    transform: rotate(45deg);
                                }
                            }
                        }
                    }

                    
                }
            }
        }

        .demo-encloser {
            padding: 0 2rem 0 1rem;

            background-color: com.$gray-1;
            border: 1px solid com.$gray-2;
            border-top-color: transparent;
            position: relative;
            .right-side-angle {
                position: absolute;
                // height: 100%;
                // width: 200px;
                z-index: 0;
                width: 150px;
                height: 462px;
                bottom: -96px;
                right: 0;
                
                // background-size: cover;
                // background-position: right;
    
            }


            .demo-rel {
                position: relative;
                width: 100%;

                .demo-binder {
                    position: absolute;
                    width: calc(100% + 3rem);
                    bottom: 0px;
                    left: -1rem;
                    height: 300px;
                    background-color: com.$gray-1;
                    border-left: 1px solid com.$gray-2;
                }
            }

            .vid-p {
                font-size: 14px;
                color: com.$black-2;
                padding-top: 8px;
            }

            .vid-div {
                // width: 100%;
                // height: 90%;
                z-index: 1;
                position: relative;
                cursor: pointer;
                video {
                    height: auto;
                }
                .vid-loader {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    background-size: cover !important;
                    background-position: left !important;
                    min-height: 300px;
                    .vid-spin-div {
                        position: absolute;
                        top: calc(50% - 16px);
                        right: calc(50% - 16px);
                    }
                }
            }
        }
    }

    .main-rec-wrapper {
        background-color: com.$gray-3;
        border-top: 1px solid com.$gray-2;
        padding-bottom: 1rem;
        padding-top: 0.5rem;
        position: relative;
        z-index: 1;

        .rec-head {
            padding: 1rem 2rem;

            .main-h-2 {
                color: com.$blue-1;
            }
        }

        .years-row {
            padding-left: 2rem;
            padding-right: 2rem;
            .each-rec {
                padding-bottom: 1rem;
            }

            .each-rec-tit {
                display: flex;
                width: 100%;
                align-items: center;
                padding-bottom: 1rem;

                .rec-img {
                    width: 82px;
                    height: 75px;
                    // height: 90%;
                    padding: 0 6px;
                    // margin-top: 2%;

                    &.f {
                        
                        background-size: contain !important;
                    }

                    &.s {
                        
                        background-size: contain !important;
                    }

                    &.t {
                        
                        background-size: contain !important;
                    }
                }

                .data-wrap {
                    width: calc(100% - 82px);
                    padding: 0 8px;
                    display: block;
                    padding-left: 12px;

                    .rec-y {
                        font-size: 14px;
                        color: com.$blue-1;
                        padding-bottom: 6px;
                    }

                    .rec-t {
                        font-size: 16px;
                        color: com.$blue-1;
                        padding-bottom: 6px;
                        a {
                            color: com.$blue-1; 
                        }
                    }
                    
                }
                .rec-d {
                    display: block;
                    font-size: 12px;
                    color: com.$black-2;
                }
            }
        }

        
    }

    .main-pub-event-wrapper {
        
        color: com.$white-1;
        
        border-top: 1px solid com.$gray-2;
        border-bottom: 1px solid com.$gray-2;
        position: relative;
        z-index: 1;

        .pe-title {
            padding: 1.5rem 0;
            .main-h-2 {
                margin-bottom: 0;
            }
            .car-btns {
                display: flex;
                justify-content: end;
                padding-right: 2rem;
            }
            .prev {
                padding-right: 8px;
            }
            .next, .prev {
                img {
                    cursor: pointer;
                }
            }
        }

        .pub-row {
            // padding-left: 2rem;            
            background-color: #002C59;
            flex: 0 0 50%;
            max-width: 50%;
            .pub-cards {
                overflow: hidden;
                position: relative;
                display: flex;                
                min-height: 460px; // desktop
                height: auto;
                .slide-image {
                    position: absolute;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    transform: translateX(100%);
                    padding: 0 1rem;
                    &.first {
                        transform: translateX(0%);
                    }
                }
                
                .each-pub {
                    padding-bottom: 1.5rem;
                    padding-left: 1rem;
                    .pub-wrapper {
                        display: flex;
                        flex-direction: column;
                    }
                    &.item-4 {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                    &.item-2 {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                    &.item-1 {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                    .text {
                        padding-top: 8px;
                        //padding-left: 1rem;
                        max-width: 250px;
                        font-size: 12px;
                        a {
                            color: com.$white-1;
                        }
                    }
                    .date-text {
                        padding-top: 4px;
                        color: #fff;
                        font-size: 10px;
                    }
                }

                @keyframes slide-right-in {
                    from {
                        -webkit-transform: translateX(-100%);
                        -moz-transform: translateX(-100%);
                        -ms-transform: translateX(-100%);
                        -o-transform: translateX(-100%);
                        transform: translateX(-100%);
                    }
                    to {
                        -webkit-transform: translateX(0%);
                        -moz-transform: translateX(0%);
                        -ms-transform: translateX(0%);
                        -o-transform: translateX(0%);
                        transform: translateX(0%);
                    }
                }
                
                @keyframes slide-left-in {
                    from {
                        -webkit-transform: translateX(100%);
                        -moz-transform: translateX(100%);
                        -ms-transform: translateX(100%);
                        -o-transform: translateX(100%);
                        transform: translateX(100%);
                    }
                    to {
                        -webkit-transform: translateX(0%);
                        -moz-transform: translateX(0%);
                        -ms-transform: translateX(0%);
                        -o-transform: translateX(0%);
                        transform: translateX(0%);
                    }
                }
                
                @keyframes slide-right-out {
                    from {
                        -webkit-transform: translateX(0%);
                        -moz-transform: translateX(0%);
                        -ms-transform: translateX(0%);
                        -o-transform: translateX(0%);
                        transform: translateX(0%);
                    }
                    to {
                        -webkit-transform: translateX(100%);
                        -moz-transform: translateX(100%);
                        -ms-transform: translateX(100%);
                        -o-transform: translateX(100%);
                        transform: translateX(100%);
                    }
                }
                
                @keyframes slide-left-out {
                    from {
                        -webkit-transform: translateX(0%);
                        -moz-transform: translateX(0%);
                        -ms-transform: translateX(0%);
                        -o-transform: translateX(0%);
                        transform: translateX(0%);
                    }
                    to {
                        -webkit-transform: translateX(-100%);
                        -moz-transform: translateX(-100%);
                        -ms-transform: translateX(-100%);
                        -o-transform: translateX(-100%);
                        transform: translateX(-100%);
                    }
                }
                
                .rightIn {
                    -webkit-animation: slide-right-in .5s 1 forwards;
                    -moz-animation: slide-right-in .5s 1 forwards;
                    -ms-animation: slide-right-in .5s 1 forwards;
                    -o-animation: slide-right-in .5s 1 forwards;
                    animation: slide-right-in .5s 1 forwards;
                }
                
                .leftIn {
                    -webkit-animation: slide-left-in .5s 1 forwards;
                    -moz-animation: slide-left-in .5s 1 forwards;
                    -ms-animation: slide-left-in .5s 1 forwards;
                    -o-animation: slide-left-in .5s 1 forwards;
                    animation: slide-left-in .5s 1 forwards;
                }
                
                .rightOut {
                    -webkit-animation: slide-right-out .5s 1 forwards;
                    -moz-animation: slide-right-out .5s 1 forwards;
                    -ms-animation: slide-right-out .5s 1 forwards;
                    -o-animation: slide-right-out .5s 1 forwards;
                    animation: slide-right-out .5s 1 forwards;
                }
                
                .leftOut{
                    -webkit-animation: slide-left-out .5s 1 forwards;
                    -moz-animation: slide-left-out .5s 1 forwards;
                    -ms-animation: slide-left-out .5s 1 forwards;
                    -o-animation: slide-left-out .5s 1 forwards;
                    animation: slide-left-out .5s 1 forwards;
                }
            }
            .bubbles-row {
                justify-content: center;
                position: relative;
                padding-bottom: 1rem;
                .inner-bubble {
                    width: 10px;
                    height: 10px;
                    cursor: pointer;
                    background-color: #666;
                    border-radius: 50%;
                    margin-right: 8px;
                    &.active {
                        background-color: #fff;
                    }
                }
            }
        }
        .events-row {
            background-color: com.$blue-1;
            flex: 0 0 50%;
            max-width: 50%;
            .each-vid {
                padding-bottom: 2.5rem;
            }
            .vid-title {
                width: 100%;
                display: flex;
                align-items: center;
                padding-top: 0.5rem;
                .vid-img {

                }
                
                .vid-text {
                    align-self: center;
                    font-size: 12px;
                    color: com.$white-1;
                    padding-left: 6px;
                    .vid-date {
                        font-size: 10px;
                        padding-top: 4px;
                    }
                }
            }
            #yt_player_0, #yt_player_1, #yt_player_2, #yt_player_3 {
                .ytp-show-cards-title {
                    display: none;
                }
            }
        }

        .km-row {
            padding-left: 2rem;
            padding-top: 2rem;
            padding-bottom: 2rem;
            padding-right: 2rem;

            // .each-km {}

            .km-head {
                width: 100%;
                font-size: 20px;
                display: flex;
                padding-bottom: 1rem;

                .km-img {
                    width: 60px;
                    height: 60px;

                    &.f {
                        
                        background-size: contain !important;
                    }

                    &.s {
                        
                        background-size: contain !important;
                    }

                    &.t {
                        
                        background-size: contain !important;
                    }
                }

                .km-t {
                    align-self: center;
                    padding-left: 1rem;
                    width: calc(100% - 60px);
                }

            }

            .km-data {
                font-size: 14px;
            }
        }
    }

    // .main-part-wrapper {
    //     background-color: com.$gray-3;
    //     border-top: 1px solid com.$gray-2;
    //     border-bottom: 1px solid com.$gray-2;
    //     position: relative;

    //     .bg-globe {
    //         position: absolute;
            
    //         right: 0;
    //         top: 0;
    //         width: 25%;
    //         height: 100%;

    //     }

    //     .part-row {

    //         padding: 1rem 0;
    //         padding-left: 2rem;
    //         padding-bottom: 2rem;
    //         padding-right: 2rem;

    //         .part-head {
    //             padding-bottom: 1rem;
    //             color: com.$blue-1;
    //         }
    //         .sec, .thi {
    //             display: flex;
    //             justify-content: center;
    //         }

    //         .each-part {
    //             height: 108px;
    //             width: 334px;
                

    //             .p-wrap {
    //                 z-index: 1;
    //                 position: relative;
    //                 display: flex;
    //                 justify-content: center;
    //                 align-items: center;
    //                 width: 100%;
    //                 height: 100%;
    //                 border: 1px solid #E4E4E4;
    //                 background-color: #FFF;
    //                 border-radius: 8px;
    //                 box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.16);

    //                 .minstry {
    //                     padding-left: 16%;
    //                 }

                    
                    

                    
    //             }
    //         }
    //     }
    // }

    @media (max-width:700px) {
        
        .main-work-demo-wrapper {
            .demo-encloser {
                .demo-rel {
                    .demo-binder {
                        display: none;
                    }
                }

            }

            .how-works {
                .img-data-binder {
                    .img-div {
                        padding-right: 1rem;

                        .out-cir {
                            opacity: 0.3;

                            .dot-in {

                                .dash-1,
                                .dash-2,
                                .dash-3 {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        .main-head-wrapper {
            .title-content {
                width: 100%;

                // .title-enc {
                //     background-color: #000d2278;
                //     z-index: 1;
                //     display: flex;
                //     justify-content: center;
                //     align-items: center;
                // }
            }
        }
        
        // .main-part-wrapper {
        //     .part-row {
        //         .sec,.thi {
        //             justify-content: start;
        //         }
        //     }
        // }
    }

    // @media(max-width: 550px) {
    //     .main-head-wrapper {
    //         .title-content {
    //             // width: 100%;

    //             .title-enc {
    //                 padding-top: 72px;
    //             }
    //         }
    //     }
    // }

    @media (max-width: 500px) {
        .main-work-demo-wrapper {
            .demo-encloser {
                .vid-div {
                    .vid-loader {
                        min-height: 246px;
                    }
                }
            }
        }        
    }

    @media(max-width:1286px) and (min-width:1024px) {
        .main-work-demo-wrapper {
            .how-works {
                .img-data-binder {
                    .img-div {
                        padding-right: 1rem;

                        .out-cir {
                            .arc {
                                .dot-in {

                                    .dash-1,
                                    .dash-3 {
                                        // position: absolute;                                        
                                        width: 130px;
                                        left: -104px;
                                        bottom: 52px // border-top: 1px dashed com.$black-1;
                                            // transform: rotate(45deg);
                                    }

                                    .dash-2 {
                                        //  position: absolute;                                        
                                        width: 42px;
                                        left: -30px;
                                        bottom: 23px // border-top: 1px dashed com.$black-1;
                                            // transform: rotate(45deg);
                                    }

                                }
                            }
                        }
                    }
                }
            }
        }

    }

    

    @media (max-width:1800px) and (min-width: 700px) {
        .main-work-demo-wrapper, .main-pub-event-wrapper  {
            .how-works {
                flex: 0 0 58%;
                max-width: 58%;
            }

            .demo-encloser {
                flex: 0 0 42%;
                max-width: 42%;
            }
        }
    }

    @media (min-width: 1700px) {
        .main-head-wrapper {
            .head-laptop {
                right: 14%;
                bottom: 2px;
            }
        }

        .main-work-demo-wrapper {
            
            .how-works {
                .img-data-binder {
                    .img-div {
                        padding-right: 15%;
                    }
                }
            }

            .demo-encloser {
                .vid-div {
                    .vid-loader {
                        min-height: 371px;
                    }
                }
            }

        }

        // .main-part-wrapper {
        //     .part-row {
        //         .each-part {
        //             height: 124px;
        //             width: 350px;                

                    
        //         }
        //     }
        // }

    }

    @media (max-width: 1400px) and (min-width: 980px) {
        .main-rec-wrapper {
            .years-row {
                .each-rec {
                    .data-wrap {
                        .rec-d {
                            padding-top: 24px;
                        }
                    }
                }

                .each-rec:first-child {
                    .data-wrap {
                        .rec-d {
                            padding-top: 0;
                        }
                    }
                }
            }
        }
        .main-work-demo-wrapper {
            .main-h-2 {
                padding-top: 2rem;
            }
            .vid-p {
                padding-bottom: 1rem;
            }
            .demo-encloser {
                .vid-div {
                    video {
                        height: 348px;
                        margin-top: -20px;
                        cursor: pointer;
                    }   
                    .vid-loader {
                       //  min-height: 348px;
                    }             
                }
            }

            .data-div {
                .bind.sec {
                    padding-top: 0.5rem !important;
                    padding-bottom: 0.5rem !important;
                }
            }
            
        }
    }

    @media (max-width: 767px) { // all devices with lesser width then 768px, mobiles
        .main-head-wrapper {
            background-position-x: 22%, 22% !important ;
            min-height: 460px;
            height: 460px;
            .head-laptop {
                left: 20%;
                height: 50%;
                min-width: 235px;
                width: 235px;
                height: 168px;
                right: 0;
                bottom: -12px;
            }
            .side-angle {
                height: 40%;
                width: 53px;
                top: 113px;
            }
            .title-content {
                height: calc(100% - 100px);
                .title-enc {
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    .pt-3 {
                        padding-top: 0.5rem !important;
                    }
                }
            }
        }

        .main-work-demo-wrapper {
            .how-works {
                padding-left: 1rem;
                .img-data-binder {
                    .data-div {                        
                        flex: 0 0 100%;
                        max-width: 100%;
                        .head {
                            font-size: 14px;
                        }
                        .data {
                            font-size: 12px;
                        }
                    }
                    .img-div {                        
                        flex: 0 0 100%;
                        max-width: 100%;
                        position: relative;
                        .out-cir {
                            position: absolute;                            
                            bottom: 70px;
                            left: calc(50% - 140px);
                            width: 280px;
                            height: 280px;
                            opacity: 0.1;
                        }
                    }
                }                
            }
            .demo-encloser {
                .right-side-angle {
                    width: 154px;
                    height: 224px;
                    bottom: 0px;
                    right: -84px;
                }
            }            
            
        }
        .main-pub-event-wrapper {
            .pub-row {
                // padding-left: 0.5rem;
                flex: 0 0 100%;
                max-width: 100%;
                .pub-cards {
                    min-height: 280px;
                    .each-pub {
                        img {
                            width: 326px;
                            height: 196px;
                        }
                        .text {
                            max-width: 326px;
                        }
                    }
                }
                
            }
            .events-row {
                flex: 0 0 100%;
                max-width: 100%;
                .each-vid {
                    padding-bottom: 1.5rem;
                    iframe {
                        height: 190px;
                    }
                    .vid-title {
                        padding-top: 0.3rem;
                    }
                }
            }
        }
        .main-rec-wrapper {
            z-index: 1;
            .rec-head {
                padding: 1rem 0.5rem;
            }
            .years-row {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                .each-rec-tit {
                    .data-wrap {
                        .rec-y {
                            font-size: 12px;
                        }
                        .rec-t {
                            font-size: 14px;
                        }                        
                    }
                    .rec-d {
                        font-size: 12px;
                    }
                }
            }
        }
        // .main-part-wrapper {
        //     .part-row {
        //         padding-left: 0.5rem;
        //         padding-right: 0.5rem;
        //         .each-part {
        //             width: calc(100% - 2px);
        //             margin-bottom: 1rem;
        //         }
        //     }
        //     .bg-globe {
        //         height: 275px;
        //         width: 200px;
        //         background-position-x: 65% !important;
        //         top: -82px;
        //     }
        // }
       
    }

    @media (max-width: 1024px) and (min-width:768px) { // all tab devices within this range, inclusive 768px
        .main-head-wrapper {
            background-position-x: 2%, 2% !important ;
            min-height: 400px;
            height: 400px;
            .title-content {
                width: 50%;
            }
            .side-angle {
                height: 40%;
                width: 46px;
                top: 113px;
            }
            .head-laptop {
                min-width: 350px;
                max-height: 250px;
                bottom: 6px;
                left: calc(75% - 190px);
            }
            .title-content {
                .title-enc {
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    .pt-3 {
                        padding-top: 0.5rem !important;
                    }
                }
            }
        }
        .main-work-demo-wrapper {
            .how-works {
                padding-left: 1rem;
                .img-data-binder {
                    .data-div {
                        // width: calc(100% - 450px);
                        // flex: 0 0 calc(100% - 450px);
                        // max-width: calc(100% - 450px);
                        .data {
                            font-size: 12px;
                        }
                    }
                    .img-div {
                        // width: 450px;
                        // flex: 0 0 450px;
                        // max-width: 450px;
                        padding-left: 2rem;
                        justify-content: center;
                    }
                }
            }
            .how-works, .demo-encloser {
                flex: 0 0 100%;
                max-width: 100%;
            }
            .demo-encloser {
                .demo-rel {
                    display: none;
                }
                .vid-div {
                    video {
                        height: auto;
                        margin-top: 0;
                    }
                }
                .main-h-2 {
                    margin-bottom: 1.5rem;
                }
            }
        }
        .main-pub-event-wrapper {
            .pub-row {
                // padding-left: 1rem;
                flex: 0 0 100%;
                max-width: 100%;
                .pub-cards {
                    min-height: 280px;
                    .each-pub {
                        img {
                            width: 330px;
                            height: 190px;
                        }
                        .text {
                            max-width: 330px;
                        }
                    }
                }
            }
            .events-row {
                flex: 0 0 100%;
                max-width: 100%;
                .each-vid {
                    padding-bottom: 1.5rem;
                    iframe {
                        height: 170px;
                    }
                    .vid-title {
                        padding-top: 0.3rem;
                    }
                }
            }
        }
        .main-rec-wrapper {
            .years-row {
                .each-rec {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }
        // .main-part-wrapper {
        //     .part-row {
        //         padding-left: 0.5rem;
        //         padding-right: 0.5rem;
        //         .each-part {
        //             width: calc(100% - 2px);
        //             margin-bottom: 1rem;
        //             .den {
        //                 img {
        //                     width: 207px;
        //                     height: 48px;
        //                 }                        
        //             }
        //             .kor {
        //                 .k img {
        //                     width: 82px;
        //                     height: 54px;
        //                 }
        //                 .minstry img {
        //                     width: 71px;
        //                     height: 69px;
        //                 }
        //             }
        //             .fias {
        //                 img {
        //                     width: 198px;
        //                     height: 56px;
        //                 }
        //             }
                    
        //         }
        //     }
        //     // .bg-globe {
        //     //     height: 275px;
        //     //     width: 200px;
        //     //     background-position-x: 65% !important;
        //     //     top: -82px;
        //     // }
        // }
        
    }

        @media (max-width: 850px) and (min-width:768px) {
            .main-work-demo-wrapper {
                .how-works {
                    .img-data-binder {
                        .img-div {
                            //padding-left: 5rem;
                            justify-content: end;
                            padding-right: 2rem;
                            padding-top: 2rem;
                        }
                    }
                }
            }
        }

}

.pub-home-main,
.reg-main,
.contact-us-main{
    font-family: com.$font-family1;
    font-style: com.$font-style1;
    font-weight: com.$font-weight1;
    background-color: com.$white-1;
    height: auto;

    .err-text {
        color: com.$red-1;
        font-size: 12px;
        padding-top: 5rem;
    }

    .color-red {
        color: com.$red-2;
    }

    .main-h-1 {
        font-size: 26px;
    }

    .main-h-2 {
        font-size: 20px;
    }

    .sub-h-3 {
        font-size: 14px;
    }

    .row {
        margin-left: 0;
        margin-right: 0;
    }
    @media (max-width: 767px) { // all devices with lesser width then or equal 767px, mobiles
        .main-h-1 {
            font-size: 16px !important;
        }
        .main-h-2 {
            font-size: 16px !important;
        }
        .sub-h-3 {
            font-size: 12px !important;
        }
    }
    @media (max-width: 1024px) and (min-width:768px) { // all tab devices within this range, inclusive 768px
        .main-h-1 {
            font-size: 20px !important;
        }
        .main-h-2 {
            font-size: 20px !important;
        }
        .sub-h-3 {
            font-size: 12px !important;
        }
        
    }
}
.reg-main {
    height: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;

    .spin-div {
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .reg-body {
        margin-top: 80px;        
        margin-bottom: 0;
        padding: 0px 25px; 
        padding-bottom: 2rem;
        z-index: 1;
        position: relative;
        background-color: com.$white-1;
        &.lr {
            padding: 0;
        }  
        .reg-head {
            .tr-wrapper {
                display: inline-flex;
                .sub-info {
                    &.left {
                        max-width: 80%;
                    }
                }
            }
            h1 {
                font-size: 24px;
                padding-left: 1.2rem;
                margin-bottom: 0;
                font-weight: com.$font-weight1;
                color: com.$blue-1;
            }
            .sub-info{
                padding-left: 1.2rem;
                font-size: 14px;
                margin: 8px 0px 0px;

                span{
                    cursor: pointer;
                    color: com.$blue-2;
                    text-decoration: underline;
                }
                &.tr-link {
                    text-align: right;
                    margin-left: auto;
                    padding-right: 1rem;
                }

            }
        }
        .btn-row{
            padding-top: 2.5rem;
            text-align: center;
            margin: 0px 10px;

            .signup-btn{
                font-weight: 500;
            }
            .inst-pdf{
                border: 1px solid com.$blue-1;
                border-radius: 5px;
                color: com.$blue-1;
                font-weight: 500;
                font-size: 14px;
            }
            .inst-pdf:hover{
                background-color: #eee;
            }
        }
        .reg-limit-head {
            background: linear-gradient(180deg,#e1e9f3 -19.67%,#fbfcfe 50%);
            padding: 2.5rem 0;
            h1 {
                text-align: center;
                font-size: 24px;
                padding-left: 1.2rem;
                font-weight: com.$font-weight1;
            }
        }
        .reg-content {
            // padding-right: 9rem;
            padding-top: 1rem;
            // .blur-img {
            //     display: flex;
            //     justify-content: end;

            //     .img-enc {
            //         position: relative;
            //         margin-top: -16px;
            //         border: 1px solid com.$gray-4;
            //         border-radius: 8px;
            //         padding: 2px;
            //         img {
            //             min-height: 220px;
            //             filter: blur(2px);
            //         }
            //         .high {
            //             font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            //             position: absolute;
            //             top: calc(50% + 12px);
            //             left: 24px;
            //             font-size: 10px;
            //             padding: 3px 5px;
            //             border: 2px solid com.$primary-blue;
            //             background-color: com.$white-1;
            //             z-index: 1;
            //             .b {
            //                 color: #1b1b1b;
            //             }
            //             .bl {
            //                 color: #0067b8;
            //             }
            //         }
            //     }

            // }
            // // padding: 0 5rem;
            // .su {
            //     // display: flex;
            //     max-width: 580px;

            //     .main-h-2 {
            //         padding-bottom: 1rem;
            //         color: com.$blue-1;
            //         font-weight: com.$font-weightBold;
            //     }
            //     .pdf-link {
            //         padding-left: 4px;
            //         text-decoration: underline;
            //         color: com.$blue-2;
            //         cursor: pointer;
            //     }

            //     .btn-row {
            //         padding-top: 1rem;
            //         .btn-2 {
            //             padding-left: 1.5rem;
            //             text-decoration: underline;
            //             color: com.$blue-2;
            //             cursor: pointer;
            //         }
            //     }

            //     p {
            //         color: com.$black-2;
            //     }
            // }
            .reg-limit {
                .hea,.txt,.inp,.bac {
                    .col-12 {
                        display: flex;
                        justify-content: center;
                    }
                }
                .hea {
                    .main-h-2 {
                        padding-bottom: 1rem;
                        color: com.$blue-1;
                        font-weight: com.$font-weightBold;
                    }
                }
                .txt {
                    p {
                        max-width: 600px;
                        color: com.$black-2;
                        padding: 0 1.5rem;
                    }
                }
                .inp {
                    .inp-wrap {
                        padding: 6px 10px;
                        border: 1px solid #1CABE357;
                        width: 100%;
                        max-width: 600px;
                        display: flex;
                        justify-content: end;
                        border-radius: 5px;
                        box-shadow: 0 4px 4px 0 #455B6317;
                        input {
                            border: none;
                            outline: none;
                            width: calc(100% - 86px);
                            
                        }
                        .btn {
                            margin-left: 1rem;
                        }
                    }
                    &.has-err {
                        .inp-wrap {
                            border-color: com.$red-2;
                        }
                    }
                    .err-msg {
                        display: flex;
                        justify-content: center;
                        // max-width: 600px;
                        width: 100%;
                        font-size: 12px;
                        padding-top: 4px;
                        
                        span{
                            width: 100%;
                            max-width: 600px;
                            padding-left: 4px;
                        }
                    }
                }
                .bac {
                    padding-top: 1rem;
                    .btn-2 {
                        // padding-left: 1.5rem;
                        text-decoration: underline;
                        color: com.$blue-2;
                        cursor: pointer;
                    }
                }
            }
            .reg-container{                 
                .box{
                    width: 95%;
                    border: 1px solid #E4E4E4;
                    border-radius: 8px;
                    box-shadow: 0px 3px 6px 0px #455B6317;
                    background-color: #fbfbfb; 
                    margin: 0px auto 20px;
                    .head-box{
                        align-items: center;
                        gap: 10px;
                        padding-top: 20px;
                        .step-head{
                            font-size: 18px;
                            font-weight: com.$font-weight1;
                            color: com.$blue-1;
                            p{
                                margin: 0px;
                            }
                        }
                    }
                }
                .sso-instruction, .reg-instruction, .ms-instruction{
                    .reg-slide{
                        text-align: left;
                        .steps{
                            display: grid;
                            grid-template-columns: 2fr 1fr 2fr 1fr 2fr;
                            row-gap: 35px;                              
                            padding: 20px 40px 15px 62px;  
                            .ss-wrapper{
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: space-between;                                 
                                width: 260px;
                                p{
                                    font-size: 14px;
                                    font-weight: com.$font-weight1;
                                    margin-bottom: 0px;
                                    color: com.$black-2;
                                    padding-right: 0px;
                                }
                                .trouble-3 {
                                   a {
                                    word-break: break-all;
                                   }                                   
                                }
                                .ss-box{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 260px;
                                    height: 256px;
                                    border-radius: 8px;
                                    border: 1px solid #D4D4D4;
                                    margin-top: 8px;
                                    background-color: #fff;
                                }  
                            }
                            .next-arw{
                                img{
                                    display: block;
                                    margin: 0px auto;
                                }                                        
                            }
                            .tab-arw{
                                display: none;
                            }
                        }
                    }                                       
                }
                .sso-instruction{
                    .reg-slide{
                        .steps{
                            .ss-wrapper{                                 
                               //  width: 100%; 
                            }
                        }
                    }
                }
                .reg-instruction{
                    background: #fbfbfb;
                    .step2{
                        background: #F4F4F4;
                        .skip-row{
                            padding: 0px 45px;
                            p{
                                border-top: 1px solid #CCCCCC;
                                border-bottom: 1px solid #CCCCCC;
                                margin-bottom: 0px;
                                padding: 10px 5px;
                                span{
                                    cursor: pointer;
                                    color: com.$blue-2;
                                    text-decoration: underline;
                                }
                            }                            
                        }
                        .skip-row:last-child {
                            p{
                                border-bottom: 0;
                                padding: 12px 5px;
                            }
                        }
                    }
                }
                .qr-instruction{ 
                    .qr-wrap{
                        display: flex;
                        .qr-img{
                            display: block;
                            margin: 30px 0;
                        }                    
                    }                                                    
                }  
                .ms-instruction{
                    // margin-bottom: 150px;
                    margin-bottom: 0;
                    .reg-slide{
                        .steps{                               
                            .ss-wrapper{
                                .ss-box{
                                    height: 175px;
                                }
                            } 
                            .next-arw{
                                img{
                                    transform: translateY(30px);
                                }
                            }                           
                        }
                    }
                }       
            }
        } 
        .scroll-top-btn{
            border: 1px solid #E4E4E4;
            border-radius: 20px;
            box-shadow: 0px 3px 6px 0px #455B6317;
            background: #fbfbfb;
            align-items: center;
            gap: 8px;  
            position: fixed;
            bottom: 80px;
            right: 60px;      
            z-index: 999; 
            img{
                display: block;
                transform: rotate(-90deg);
            }
            .btn-text{
                font-size: 14px;
                font-weight: com.$font-weight1;
                color: com.$blue-1;
                p{
                    margin: 0px;
                }
            }
        }   
    }
}

@media (max-width:1024px) and (min-width:768px){
    .reg-main{
        .reg-body{
            margin-top: 80px;
            padding-bottom: 2rem;
            .reg-head {
                .col-12{
                    padding-left: 0px;
                }
                h1 {
                    padding-left: 0;
                    padding-top: 1rem;
                    font-size: 20px;
                }
                .sub-info{
                    padding-left: 0;    
                }
            }
            .reg-content{
                .reg-container{
                    .box{
                        margin-left: 0px;
                        margin-right: 0px;
                        width: 100%;
                        .head-box{
                            img{
                                width: 35px !important;
                                height: 35px !important;
                            }
                        }
                        .pl-5{
                            padding-left: 20px !important;
                        }
                    }
                    .reg-instruction, .ms-instruction{
                        .reg-slide{
                            .steps{
                                grid-template-columns: 1fr 1fr 1fr;
                                padding-left: 30px;
                                .arw-off{
                                    display: none;
                                }
                                .tab-arw{
                                    display: inline-block;
                                }
                            }
                        }
                        
                    }
                    .sso-instruction{
                        .reg-slide{
                            .steps{
                                grid-template-columns: 1fr 1fr 1fr;
                                padding-left: 30px;
                            }
                        }                        
                    }
                    .reg-instruction{
                        .step2{
                            .skip-row{
                                padding: 0px;
                                .col-12{
                                    padding: 0px !important;
                                    p{
                                        padding-left: 30px;
                                    }
                                }
                            }
                        }
                    }
                    .qr-instruction{ 
                        .qr-wrap{
                            .qr-img{
                                width: 91px !important;
                                height: 91px !important;
                            }                    
                        }                                                    
                    }
                    .ms-instruction{
                        // margin-bottom: 3rem;
                    }       
                }
            }
        }
        .scroll-top-btn{
            bottom: 110px !important;
            right: 20px !important;
            gap: 8px;
        } 
    }
}

@media (max-width:767px) {
    .reg-main{
        .reg-body {
            padding: 0px;
            margin-top: 100px;
            padding-bottom: 2rem;
            margin-bottom: 0;
            .reg-head {
                h1 {
                    padding-left: 0;
                    padding-top: 1rem;
                    font-size: 18px;
                }
                .sub-info{
                    padding-left: 0;
                    padding-right: 15px; 
                    font-size: 12px;  

                }
            }
            .btn-row{
                padding-top: 0rem;
                margin-left: 0px;
                margin-right: 0px;
                .signup-btn{
                    width: 100%;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
                .inst-pdf{
                    display: block;
                    width: 100%;
                    margin-top: 15px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
                .ml-3{
                    margin-left: 0px !important;
                }
            }
            .reg-content {
                .reg-container{
                    .box{
                        margin-left: 0px;
                        margin-right: 0px;
                        width: 100%;
                        .head-box{
                            img{
                                width: 35px !important;
                                height: 35px !important;
                            }
                            .step-head{
                                font-size: 16px;
                                p{
                                    padding-right: 20px;
                                }
                            }
                        }
                        .pl-5{
                            padding-left: 15px !important;
                        }
                    }
                    .reg-instruction, .ms-instruction, .sso-instruction{
                        .reg-slide{
                            .steps{
                                padding: 15px;
                                grid-template-columns: 1fr;
                                row-gap: 12px;
                                .next-arw{
                                    img{
                                        transform: rotate(90deg);
                                    }
                                }
                                .tab-arw{
                                    display: inline-block;
                                }
                                .ss-wrapper{
                                    width: 100%;
                                    span{
                                        padding-left: 10px;
                                    }
                                    .ss-box{
                                        width: 100%;
                                        height: 300px;
                                        .ss1{
                                            width: 221px !important;
                                            height: 287px !important;
                                        }
                                        .ss2{
                                            width: 277px !important;
                                            height: 285px !important;
                                        }
                                        .ss3{
                                            width: 268px !important;
                                            height: 279px !important;
                                        }
                                        .ss4{
                                            width: 311px !important;
                                            height: 258px !important;
                                        }
                                        .ss5{
                                            width: 315px !important;
                                            height: 172px !important;
                                        }
                                        .ss6{
                                            width: 313px !important;
                                            height: 260px !important;
                                        }
                                        .ss7{
                                            width: 294px !important;
                                            height: 271px !important;
                                        }
                                        .ss8{
                                            width: 315px !important;
                                            height: 239px !important;
                                        }
                                        .ss9{
                                            width: 243px !important;
                                            height: 288px !important;
                                        }
                                        .ss10{
                                            width: 238px !important;
                                            height: 284px !important;
                                        }
                                        .ss11{
                                            width: 237px !important;
                                            height: 281px !important;
                                        }
                                        .ss12{
                                            width: 294px !important;
                                            height: 276px !important;
                                        }
                                        .ss13{
                                            width: 224px !important;
                                            height: 282px !important;
                                        }
                                        .ss14{
                                            width: 297px !important;
                                            height: 156px !important;
                                        }
                                        .ss15{
                                            width: 292px !important;
                                            height: 154px !important;
                                        }
                                        .ss16{
                                            width: 203px !important;
                                            height: 161px !important;
                                        }
                                        .ss17{
                                            width: 286px !important;
                                            height: 159px !important;
                                        }
                                        .ss18{
                                            width: 302px !important;
                                            height: 161px !important;
                                        }
                                        .ss19{
                                            width: 307px !important;
                                            height: 153px !important;
                                        }
                                        
                                    }  
                                }
                            }
                        }
                    }  
                    .reg-instruction{
                        .step2{
                            .skip-row{
                                padding: 0px;
                                .col-12{
                                    padding: 0px !important;
                                    p{
                                        padding-left: 20px;
                                    }
                                }
                            }
                        }
                    }
                    .qr-instruction{ 
                        padding-bottom: 15px; 
                        .qr-wrap{
                            display: inline-block;
                            .pl-5{
                                padding-left: 0px !important;
                                padding-top: 15px !important;
                            }
                            p{
                                margin-bottom: 0px;
                            }
                            .px-4{
                                padding-left: 12px !important;
                                padding-right: 0px !important;
                            }
                            .qr-img{
                                width: 73px !important;
                                height: 73px !important;
                                margin: 0px;
                            }                    
                        }                                                
                    } 
                    .ms-instruction{
                        // margin-bottom: 5rem;
                        // z-index: 1;
                        // position: relative;
                        .reg-slide{
                            .steps{
                                .ss-wrapper{
                                    .ss-box{
                                        height: 175px;
                                    }
                                }
                            }
                        }
                    }      
                }
            }            
        }
        .scroll-top-btn{
            bottom: 140px !important;
            right: 10px !important;
            gap: 4px;  
            img{
                width: 20px !important;
                height: 20px !important;
            }
        } 
    }

    .mobile-nav-active {
        .reg-main {
            .scroll-top-btn {
                z-index: 1;
            }
        }
    }

    .pub-home-main .main-part-wrapper .part-row {
        .sec, .thi {
            justify-content: start;
        }
    }
}

@media (max-width: 992px) {
    .app-body {
        margin-top: 0;
    }
}


.contact-us-main{
    height: 100%;
    min-height: 100vh;
    position: relative;
    .contact-us-body{
        margin-top: 80px;
        background-color: com.$white-1;
        z-index: 1;
        position: relative;
        // padding-bottom: 320px;
        .contact-intro-box{
            // background: linear-gradient(180deg, #CEDDEF -16.67%, #FBFCFE 50%);
            background-color: #FBFCFE;
            .intro-wrapper{
                text-align: center;
                width: 50%;
                margin: 0px auto;
                padding: 50px 20px 0px;
                h2{
                    color:com.$blue-1;
                }
                .intro-text{
                    font-size: 14px;
                    line-height: 17px;
                    text-align: center;
                    color: com.$black-2;
                }
            }
        }
        .contact-form-box{
            padding-bottom: 20px;
            .form-wrapper{
                width: 40%;
                margin: 0px auto;
                padding: 20px;
                .form-note{
                    font-size: 12px;
                    text-align: right;
                    color: com.$black-3;
                    padding-right: 15px;
                }
                .main-form-row{
                    width: 100%;
                    margin: 0px auto;
                    .form-group {
                        padding-bottom: 25px;
                        margin-bottom: 0px !important;
                        .color-red {
                            font-size: 12px;
                        }
                    }
                    .form-input {                
                        input,
                        textarea {
                            padding: 5px 8px;
                            min-height: 40px;
                            width: 100%;
                            border-radius: 4px;
                            border: 1px solid #CCC;
                            resize: none;
                        }                
                        input::placeholder,
                        textarea::placeholder {
                            font-size: 12px;
                            color: #ccc;
                        }
                
                        input:focus-visible,
                        textarea:focus-visible,
                        input:focus,
                        textarea:focus,
                        input:active,
                        textarea:active {
                            border: 1px solid #2684FF;
                            outline-color: #2684FF;
                        }
                        
                        .captcha-input{
                            width: 60%;
                        }
                        .err-input{
                            border: 1px solid #AF0000 !important;
                        }

                    }
                    .form-label {
                        font-size: 14px;
                        color: com.$black-3;        
                    }
                }
                .contact-btn-row{
                    display: flex;
                    justify-content: space-between;
                    padding: 0px 15px;
                }
            }
            .err-row{
                text-align: center;
            }
        }
    }  
    .f-base {
        // position: absolute;
        // bottom: 0;
        width: 100%;
    }
}

@media(max-width: 1024px) and (min-width: 768px) {
    .contact-us-main{
        .contact-us-body{
           //  padding-bottom: 0;
            .contact-intro-box{
                .intro-wrapper{
                    width: 80%;
                    h2{
                        font-size: 18px;
                    }
                    .intro-text{
                        font-size: 12px;
                    }
                }
            }
            .contact-form-box{
                .form-wrapper{
                    width:50%;
                }                
            }
        }
    }
}

@media (max-width: 767px) {
    .contact-us-main{
        .contact-us-body {
            margin-top: 60px;
            padding-bottom: 2rem;
            .contact-intro-box{
                .intro-wrapper{
                    width: 100%;
                    h2{
                        font-size: 18px;
                    }
                    .intro-text{
                        font-size: 12px;
                    }
                }
            }
            .contact-form-box{
                .form-wrapper{
                    width: 100%;
                    .contact-btn-row{
                        display: block;
                        .submit-bttn, .reset-bttn{
                            width: 100%;
                        }
                        .submit-bttn{
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
        .f-base {
            position: relative;
            bottom: auto;
        }
    }
}