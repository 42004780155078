@use './utils/styles/publicStyleVariables' as com;

.pub-header-main {
    font-family: com.$font-family1;
    font-style: com.$font-style1;
    font-weight: com.$font-weight1;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 2;
    background: transparent;
    max-height: 80px;   
    .desktop-header-main {
        height: 80px;
    }

    &.sd {
        background: com.$white-1;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
        .link-click-binder {
            .n-links {
                color: com.$blue-1;
                

            }
            .b-clicks {
                .log-in-btn {
                        border: 1px solid com.$blue-1;
                        color: com.$blue-1;                
                        &:focus {
                            box-shadow: 0 0 0 0.1rem rgb(0 34 69);
                        }
                        &.b {
                            font-weight: com.$font-weightBold;
                        }
                    }
            }
        }
    }

    .ham-mob-menu-main {
        position: absolute;
        top: 0;
        right: -100%;
        width: 100%;
        background-color: com.$white-1;
        bottom: 0;
        transition: 0.3s;
        z-index: 9997;
        font-size: 16px;
        color: com.$blue-1;
        height: 100vh;
        overflow-y: auto;

        .ham-cro {
            width: 100%;
            height: 80px;
            background-position: center !important;
            background-size: cover !important;
            background-blend-mode: overlay;
            opacity: 0.9;
            padding: 0 1rem;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
            align-items: center;
            display: flex;

            .ifc-l-img {
                padding-left: 0.5rem;
                a {
                    text-decoration: none;
                    cursor: pointer;
                }
            }
            .mal-l-img {
                padding-right: 0.5rem;
                border-right: 1px solid com.$white-1;
                img {
                    cursor: pointer;
                }
            }

            .t {
                width: calc(100% - 33px);
                margin-right: auto;
            }
            .i img {
                filter: invert(100%) sepia(4%) saturate(121%) hue-rotate(181deg) brightness(170%) contrast(139%);
                
            }

            img {
                cursor: pointer;
                margin-right: 8px;
            }
        }

        .ham-ul {
            width: 100%;

            inset: 0;
            list-style: none;
            padding: 0;
            padding-bottom: 5rem;
            margin-bottom: 0;
            background-color: com.$white-1;
            z-index: 1;
            position: relative;
            li:first-child {
                margin-top: 1rem;
            }
            .f-row {
                width: 100%;
                position: relative;
            }

            .link {
                cursor: pointer;

                &.active {
                    color: com.$primary-blue;
                }
            }

            .btn {
                width: 100%;
                min-height: 46px;
                font-size: 14px;
                font-weight: com.$font-weightBold;
            }

            .li-btn {
                border: 1px solid com.$blue-1;
                color: com.$blue-1;
                background-color: com.$white-1;

                &:focus {
                    box-shadow: 0 0 0 0.1rem rgb(0 34 69);
                }
            }

            li {
                padding: 1rem 1rem;
            }
        }
    }

    .logo {
        display: inline-flex;
        text-align: left;
        padding-left: 3rem;
        min-width: 30%;

        .ifc-l-img {
            padding-left: 0.5rem;
            a {
                text-decoration: none;
                cursor: pointer;
            }
        }
        .mal-l-img {
            padding-right: 1rem;
            border-right: 1px solid com.$white-1;
            img {
                cursor: pointer;
            }
        }

        &.bg-w {
            .mal-l-img {
                border-right: 1px solid com.$black-3;
            }
        }
        
    }

    .link-click-binder {
        min-width: 70%;
        display: inline-block;
        text-align: right;
        padding-right: 3rem;

        .n-links {
            font-size: 16px;
            color: com.$white-1;
            padding-right: 1rem;
            .link:first-child {
                margin-right: 1.5rem;
            }
            .link.active {
                color: com.$primary-blue;
                font-weight: com.$font-weightBold;
            }
            .link:hover {
                cursor: pointer;
                &.active {
                    cursor: default;
                }
            }

        }
        .b-clicks {
            // padding-left: 15%;
            
            .btn {
                min-height: 40px;
            }
            .log-in-btn {
                margin-right: 1.5rem;
                background: transparent !important;
                border: 1px solid #fff;
                &:focus {
                    box-shadow: 0 0 0 0.1rem rgb(255 255 255);
                }
            }
        }
        .ham-menu-tab{
            display: none;
        }
        .ham-tab-dd {
            display: none;
            .dropdown-menu {
                border: 1px solid com.$gray-5;
                margin-top: 1rem;                
            }
            .dropdown-item {
                border-bottom: 1px solid com.$gray-5;
                &:last-child {
                   border-bottom: 0; 
                }
                .link {
                    font-size: 16px;
                    color: com.$blue-1;
                    &.active {
                        font-weight: com.$font-weightBold;
                        color: com.$primary-blue;
                    }
                }
                
            }
        }   
    } 
}

@media (max-width: 1024px) and (min-width: 768px){
    .pub-header-main {
        .logo { 
            padding-left: 1rem;
            .ifc-l-img {
                img{
                    width: 159px !important;
                    height: 35px !important;
                }
            }
            .mal-l-img {
                img{
                    width: 147.5px !important;
                    height: 42.85px !important;
                }
            }            
        }
        .link-click-binder {
            padding-right: 1rem;
            .n-links {
                display: none;
            }
            .ham-menu-tab{
                display: none;
            }
            .ham-tab-dd {
                display: inline-block;    
            }
        }        
        
        &.ham-tab-active {
           .ham-tab-dd {

           }
        }
    }
}

@media (max-width: 767px){
    .pub-header-main {
        .desktop-header-main {
            height: 60px;
        }
        .logo {  
            padding-left: 0.5rem; 
            width: calc(100% - 50px);   
            .ifc-l-img {
                img{
                    width: 120px !important;
                    height: 27px !important;
                }
            }
            .mal-l-img {
                img{
                    width: 108px !important;
                    height: 31px !important;
                }
            }            
        }
        .link-click-binder {
            min-width: 50px;
            padding-right: 0.5rem;
            .n-links, .b-clicks {
                display: none;
            }
            .ham-menu-tab{
                display: inline-block;
            }
        }
        
        &.ham-mob-active {
            max-height: none; 
            .ham-mob-menu-main {
                right: 0;
            }           
        }
                      
        
    }
}
@media (max-width: 560px){
    .pub-header-main{
        // .logo{
        //     padding-left: 0;
        // }
        // .link-click-binder{
        //     padding-left: 0;
        // }
    }
}