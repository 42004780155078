
.tou-modal-main {
    color: #666;
    font-style: normal;
    &.modal-dialog {
        max-width: 55%;
    }
    
    .modal-header {
        background-color: #2CA9D6;
        .modal-title {
            font-size: 15px;        
            font-weight: 500;
            line-height: 16px;
            color: #FFF;
        }        
        border-bottom: 0;
        border-radius: 0;
        .close-icon {
            cursor: pointer;
        }
    }
    .modal-body {
        padding-left: 1rem;
        padding-right: 1rem;
        max-height: 350px;
        overflow-y: auto;
    }
    .modal-body:focus,.modal-body:focus-visible,.modal-body:focus-within {
        outline: none;
    }
    .modal-footer {
        .buttons-row {
            width: 100%;
            padding-right: 1rem;
            .dec {
                display: flex;
                justify-content: start;
            }
            .agr {
                display: flex;
                justify-content: end;
                padding-right: 2rem;
            }
        }

    }
    .heading-1 {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
    }
    .content-1 {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
    }
    .modal-body {
        .row {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.mod-box {
    .close-icon {
        display: none;
    }

    .cbox {
        display: flex;
        padding-bottom: 0.5rem;
        label {
            margin-bottom: 0;
            .tr-links{
                text-decoration: underline;
                text-decoration-color: #0049C8;
                color: #0049C8;
                cursor: pointer;
            }
        }
        input[type = checkbox] {
            min-width: 18px;
            margin-right: 8px;
            cursor: pointer;
        }
    }
}

.tc-pp-cs-main {
    width: 100%;
    height: 100%;
    background: #FFF;
    color: #666;
    margin-top: 0.5rem;
    .a-link {
        cursor: pointer;
        color: #fff;        
    }
    .a-link:hover {
        text-decoration-line: underline;        
    }
    &.page-type {
        .tc-sec, .pp-sec,.cs-sec {
            padding: 1rem;
            padding-bottom: 2rem;
            padding-left: 1.5rem;
            a {
                color: #0049C8;
            }
        }
    }
    .under-line{
        display: inline-block;
        text-decoration: underline;
        margin-bottom: 12px;
    }
    .tr-links{
        text-decoration: underline;
        text-decoration-color: #0049C8;
        color: #0049C8;
        cursor: pointer;
    }
   
        .footer-row.cs-foot {
            position: absolute;
            width: 100%;
            bottom: 0;
        }
    
    ul, ol {
        padding-inline-start: 24px;
    }
    .footer-row {
        margin-left: 0;
        margin-right: 0;
        background: #6E7787;
        color: #FFF;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        .img-2 {
            width: 138px;
            height: 30px;
        }
        a, a:hover {
            color: #fff;
        }
        .text-col {
            display: flex;
            justify-content: end;
            align-self: center;
        }
    }
}

.ppp-main, .ptc-main{
    .privacy-wrap, .terms-wrap{
        margin-top: 80px;
    }
}

.pcs-main {
    position: relative;
    .f-base {
        position: absolute;
        width: 100%;
        bottom: 0;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .tou-modal-main {
        &.modal-dialog {
            max-width: 95%;
        }  
        .modal-footer {
            .row{
                margin: 0px !important;
            }
            .buttons-row {
                padding-right: 0;
                .col-6{
                    padding: 0px !important;
                }
            }
    
        }      
    }
}

@media (min-width: 1700px) and (max-width: 1920px) {
    .tou-modal-main {
        .modal-body {
            max-height: 500px;
        }        
    }
}

@media (min-width: 1920px) {
    .tou-modal-main {
        .modal-body {
            max-height: 600px;
        }        
    }
}

@media (max-width: 767px){
    .tou-modal-main {
        .modal-header{
            font-size: 14px;
        }
        .modal-body {
            max-height: calc(100vh - 200px);
        }  
        &.modal-dialog {
            max-width: 95%;
        }  
        .cbox {
            label {
                font-size: 12px;
            }
        }
        .modal-footer {
            .row{
                margin: 0px !important;
            }
            .buttons-row {
                padding-right: 0;
                .col-6{
                    padding: 0px !important;
                }
            }
    
        }       
    }
    .popup-type {
        p{
            font-size: 12px;
        }
    }

    .ppp-main, .ptc-main, .pcs-main{
        .row {
            margin-left: 0;
            margin-right: 0;
        }
        .privacy-wrap, .terms-wrap{
            margin-top: 60px;
        }
    }

    .pcs-main {
        .f-base {
            position: relative;
            
        }
    }
}