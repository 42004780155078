@use './utils/styles/publicStyleVariables' as com;

.pub-footer-main {
    font-family: com.$font-family1;
    font-style: com.$font-style1;
    font-weight: com.$font-weight1;
    width: 100%;

    .main-h-2 {
        font-size: 20px;
    }    
    border-top: 1px solid com.$gray-2;
    .f-t-row {
        background-color: com.$blue-1;
        width: 100%;
        position: relative;
        z-index: 1;
    }
    .foot-row {
        display: flex;
        padding: 1rem 2rem 0.8rem;
        align-items: center;
        
        
        .img-row {
            display: flex;
            justify-content: start; 
            padding: 0px;  
            width: 30%;  
            a {
                text-decoration: none;
                cursor: pointer;
            }       
        }
        .links-row {
            width: 70%;
            display: flex;
            justify-content: end;
            align-items: center;
            color: com.$white-1;
            font-size: 14px;
            padding: 0px;
            .link:hover {
                
                cursor: pointer;
            }
            .link {
                a {
                    color: com.$white-1;
                }
            }
        }
    }
    .text-tab{
        padding: 0 1rem 1rem 2rem;
        color: com.$white-1;
        
        display: none;
    }
        .main-part-wrapper {
            background-color: com.$gray-3;
            border-top: 1px solid com.$gray-2;
            border-bottom: 1px solid com.$gray-2;
            position: relative;
    
            .bg-globe {
                position: absolute;    
                right: 0;
                bottom: -60px;
                width: 203px;
                height: 279px;
    
            }
    
            .part-row {
    
                padding: 1rem 0;
                padding-left: 2rem;                
                padding-right: 2rem;
    
                .part-head {
                    padding-bottom: 0.5rem;
                    color: com.$blue-1;
                }
                .part-content {
                    display: inline-flex;
                    align-items: center;
                }
    
                
    
                .each-part {                   
                    padding-right: 1rem;
                    &.sec {
                        padding-right: 1.5rem;
                    }
    
                    .p-wrap {
                        z-index: 1;
                        position: relative;
                        display: flex;                        
                        // width: 100%;
                        // height: 100%; 
                        &.kor .k {
                            padding-top: 10px;
                        }                      
    
                        .minstry {
                            // padding-left: 16%;
                        }
                    }
                }
            }
        }
}

.ext-footer-main {
    .main-part-wrapper {
        .part-row {
            margin: 0;
        }
    }
}

@media (max-width: 1024px) and (min-width: 768px){
    .pub-footer-main{
        .foot-row{
            .text{
                display: none;
            }
        }
        .text-tab{           
            display: block;
            background-color: com.$blue-1;
        }
        .main-part-wrapper {
            .part-row {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                .each-part {
                    
                    margin-bottom: 1rem;
                    .den {
                        img {
                            width: 175px;
                            height: 41px;
                        }                        
                    }
                    .kor {
                        .k img {
                            width: 82px;
                            height: 54px;
                        }
                        .minstry img {
                            width: 71px;
                            height: 69px;
                        }
                    }
                    .fias {
                        img {
                            width: 130px;
                            height: 37px;
                        }
                    }
                    
                }
            }
            // .bg-globe {
            //     height: 275px;
            //     width: 200px;
            //     background-position-x: 65% !important;
            //     top: -82px;
            // }
        }
    }
}

@media (max-width: 545px) {
    .pub-footer-main{
        .foot-row{
            .links-row {
                .link:nth-child(4) {
                    padding-top: 8px;
                }
            }
        }
    }
}

@media (max-width: 767px){    
    .pub-footer-main{
        .main-h-2 {
            font-size: 16px;
        }
        .foot-row{
            padding-right: 1rem;
            padding-left: 1rem;
            display: block;
            .links-row, .img-row{
                width: 100%;
            }
            .links-row{
                padding-left: 6px;
                padding-top: 10px;
                justify-content: space-between;
                flex-wrap: wrap;
                .pr-4{
                    padding-right: 16px !important;
                }
                
            }
            .img-row{
                img{
                    width: 141px !important;
                    height: 31px !important;
                }
            }
            .text{
                display: none;
            }
        }
        .text-tab{
            display: block;
            padding-left: 22px;
            letter-spacing: 0.1px;
            background-color: com.$blue-1;
        }
        .main-part-wrapper {
            .part-row {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                .each-part {
                    
                    margin-bottom: 1rem;
                    .den {
                        img {
                            width: 175px;
                            height: 41px;
                        }                        
                    }
                    .kor {
                        .k img {
                            width: 82px;
                            height: 54px;
                        }
                        .minstry img {
                            width: 71px;
                            height: 69px;
                        }
                    }
                    .fias {
                        img {
                            width: 130px;
                            height: 37px;
                        }
                    }
                    
                }
            }
            .bg-globe {
                height: 275px;
                width: 200px;
                background-position-x: 65% !important;
                top: -82px;
                z-index: 0;
            }
        }

        .main-part-wrapper .part-row {
            .part-content {
                display: flex;
                flex-direction: column;
                align-items: start;
            }
        }
    }
}

@media (min-width: 1700px) { 
    .main-part-wrapper {
        .part-row {
            
        }
    }
}