@use './publicStyleVariables' as com;
.public-modal-main {

}
.public-modal-wrapper {
    font-family: com.$font-family1;
    font-style: com.$font-style1;
    font-weight: com.$font-weight1;
    
    height: auto;
    .modal-content {
        border: none;
        background-color: com.$white-1;
    }
    .md-body{
        border: 1px solid #AF0000;
    }
    &.default-pop{
        max-width: 100% !important;
        justify-content: center;
        .modal-content{
            max-width: 380px !important;
        }
        .success-box{
            text-align: center;
            margin: 16px 0 4px 0;
            transform: translateY(-15px);
            .tick{
                width: 35px;
                height: 35px;
            }
        }
        .success-msg{
            
            color: com.$black-3;
            margin-bottom: 16px;
            text-align: center;
            .id-msg {
                font-weight: 500;
            }
            .con-head {
                font-size: 16px;
                font-weight: 500;
                padding-bottom: 1rem;
            }
        }
    }
    &.desktop {
        max-width: 100% !important;
        .modal-content {
            border: 1px solid com.$gray-4;
            border-radius: 8px;
            color: com.$black-3;
            max-width: 300px;
            margin: auto;
        }
        .modal-body {
            padding-top: 0;
        }
        .modal-header {
            padding: 0;
            border: none;
            .close-icon {
                cursor: pointer;
                z-index: 1;
                padding-top: 1rem;
                padding-right: 1.5rem;
                padding-left: 0.5rem;
            }
        }
        .do-box {
            padding: 0 1rem 1rem 1rem;
            margin-top: -12px;
            .img-row {
                display: flex;
                justify-content: center;
                padding-bottom: 1rem;
            }
            .do-text {
                display: flex;
                font-size: 14px;
            }
        }
    }
}

@media (max-width: 767px){
    .public-modal-wrapper { 
        &.default-pop{
            .modal-content{
                max-width: 300px !important;
            }
            .success-wrapper{
                .success-msg{
                    .con-head {
                        font-size: 14px !important;
                    }
                }
            }
        }
    }
}