@use './utils/styles/publicStyleVariables' as com;

.login-pre-main,
.reg-pre-main,
.pwl-main {
    font-family: com.$font-family1;
    font-style: com.$font-style1;
    font-weight: com.$font-weight1;
    background-color: com.$white-1;
    color: com.$black-2;
    height: auto;

    .main-h-1 {
        font-size: 24px;
    }

    .main-h-2 {
        font-size: 20px;
    }

    .sub-h-3 {
        font-size: 14px;
    }

    .row {
        margin-left: 0;
        margin-right: 0;
    }
}

.login-pre-main {
    height: 100%;
    min-height: 100vh;
    // overflow-x: hidden;
    position: relative;

    .login-body {
        margin-top: 80px;
        padding-bottom: 2rem;
        z-index: 1;
        position: relative;
        background-color: com.$white-1;
        .login-head {
            // background: linear-gradient(180deg, #e1e9f3 -19.67%, #FBFCFE 50%);
            // padding: 2.5rem 0;
            padding: 1rem 0;

            h1 {
                text-align: left;
                font-size: 24px;
                padding-left: 3rem;
                margin-bottom: 0;
                font-weight: com.$font-weight1;
                padding-bottom: 1rem;
                padding-top: 1rem;
            }

            .proceed {
                padding-top: 1rem;
                .signup-btn{
                    font-weight: 500;
                }
                .inst-pdf{
                    border: 1px solid com.$blue-1;
                    border-radius: 5px;
                    color: com.$blue-1;
                    font-weight: 500;
                    font-size: 14px;
                }
                .inst-pdf:hover{
                    background-color: #eee;
                }
            }

            .l-footer {
                padding-left: 4rem;
            }
        }

        .login-content {
            border: 1px solid com.$gray-4;
            background-color: #FBFBFB;
            border-radius: 8px;
            margin-right: 4rem;
            margin-left: 4rem;
            margin-bottom: 2rem;
            box-shadow: 0 6px 3px 0 rgba(69, 91, 99, 0.09);
            padding: 1rem 2rem;

            //display: flex;
            .ins-1,
            .ins-2,
            .ins-3 {
                width: 100%;
            }
        }

        .main-h-2 {
            color: com.$blue-1;
            font-weight: com.$font-weightBold;
            display: flex;
            justify-content: center;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

        .sub-h-3 {
            display: flex;
            justify-content: center;
            padding-bottom: 1rem;
            font-weight: com.$font-weight1;
            margin-bottom: 0;

        }

        .ins-head {
            padding: 0 2rem;
            font-size: 18px;
            color: com.$blue-1;
            padding-bottom: 1.5rem;
            padding-top: 1rem;
        }

        .tab-on{
            display: none;
        }
        .lg-wrap {
            width: 100%;
            display: grid;
            grid-template-columns: 2fr 1fr 2fr 1fr 2fr;
            padding: 0 1rem;
        }

        .each-ins {
            // width: 26%;
            display: flex;
            flex-direction: column;

            .text-wrap.two-c-step {
                position: relative;
                
                .two-c-step-wrap {
                    width: 225%;
                    position: absolute;
                    display: flex;
                }

            }

            .text-wrap {
                display: flex;
                flex: auto;
                width: 100%;
                font-size: 14px;
                color: com.$black-2;
            }

            .img-wrap {
                width: 100%;
                border: 1px solid com.$gray-6;
                border-radius: 8px;
                background-color: com.$white-1;

                .img-span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 4px 0px;
                    min-height: 302px;
                }
            }

        }

        .next-arr {
            // width: 11%;
            padding-top: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .mbl-arr{
            display: none;
        }

        .l-divider {
            border-top: 1px solid com.$gray-5;
            margin: 1rem -2rem;
        }

        .l-footer {
            width: 100%;
            display: flex;
            font-size: 14px;

            // padding: 0 1rem;
            .link {
                text-decoration: underline;
                cursor: pointer;
                color: #0029CB;
                // color: com.$blue-1
            }
        }
        .scroll-top-btn{
            border: 1px solid #E4E4E4;
            border-radius: 20px;
            box-shadow: 0px 3px 6px 0px #455B6317;
            background: #fbfbfb;
            align-items: center;
            gap: 8px;  
            position: fixed;
            bottom: 110px;
            right: 28px;      
            z-index: 999;             
            img{
                display: block;
                transform: rotate(-90deg);
            }
            .btn-text{
                font-size: 14px;
                font-weight: com.$font-weight1;
                color: com.$blue-1;
                p{
                    margin: 0px;
                }
            }
        }
    }



    .ft-b {
        // position: absolute;
        // bottom: 0;
        width: 100%;
    }

    .proceed {
        display: flex;
        justify-content: center;
        // position: sticky;
        // bottom:16px;
        // z-index: 1;
    }

    @media(max-height: 700px) {
        .ft-b {
            position: relative;
            // bottom: 0;
            width: 100%;
        }
        
    }

    @media(max-width: 1024px) and (min-width: 768px){
        .login-body {    
            .login-head {    
                h1 {
                    font-size: 20px;
                    padding-left: 0.5rem;
                }
                .l-footer {
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                    font-size: 14px;
                }
            }    
            .login-content {
                margin-right: 1.5rem;
                margin-left: 1.5rem;
                margin-bottom: 4rem;
                padding: 1rem;

                .ins-3{
                    .lg-wrap{
                        .each-ins {
                            .tab-off-txt{
                                display: none;
                            }                
                        }
                    }
                }
            }    
            .tab-on {     
                display: block;           
                .two-c-step-wrap {
                    display: flex;
                    width: 100%;
                    font-size: 14px;
                    color: com.$black-2;
                    padding-left: 24px;
                    padding-bottom: 15px;
                }
            }
            .lg-wrap {
                grid-template-columns: 1fr 1fr 1fr;
                row-gap: 40px;
                padding: 0 1rem;
            }
            .tab-off {
                display: none;
            }
            .mbl-arr{
                display: none;
            }
        }
    }

    @media(max-width: 767px) {
        .login-body { 
            margin-top: 60px;   
            .login-head {   
                padding-top: 40px; 
                h1 {
                    font-size: 18px;
                    padding-left: 0;
                }

                .proceed {
                    padding-top: 0rem;
                    margin-left: 0px;
                    margin-right: 0px;
                    display: block;

                    .signup-btn{
                        width: 100%;
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }
                    .inst-pdf{
                        width: 100%;
                        margin-top: 15px;
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }
                    .ml-3{
                        margin-left: 0px !important;
                    }
                }
                .l-footer {
                    padding-left: 1rem;
                    padding-right: 1rem;
                    font-size: 12px;
                }
            }    
            .login-content {
                margin: 0;
                margin-bottom: 4rem;
                padding: 1rem;

                .ins-3{
                    .lg-wrap{
                        .each-ins {
                            .tab-off-txt{
                                display: none;
                            }                
                        }
                    }
                }
                .pt-5{
                    padding-top: 0 !important;
                }
                .lg-wrap{
                    .each-ins {
                        .img-wrap{
                            .img-2a{
                                width: 309px !important;
                                height: 257px !important;
                            }
                            .img-2b{
                                width: 304px !important;
                                height: 231px !important;
                            }
                            .img-2c-1{
                                width: 240px !important;
                                height: 285px !important;
                            }
                            .img-2c-2{
                                width: 237px !important;
                                height: 282px !important;
                            }
                        }               
                    }
                }
            }    
            .tab-on {     
                display: block;           
                .two-c-step-wrap {
                    display: flex;
                    width: 100%;
                    font-size: 14px;
                    color: com.$black-2;
                    padding-left: 0px;
                    padding-top: 10px;
                    padding-bottom: 15px;
                }
            }
            .lg-wrap {
                grid-template-columns: 1fr;
                row-gap: 10px;
                padding: 0;
            }
            .next-arr {
                padding: 0px;
                transform: rotate(90deg);
            }
            .mbl-arr{
                display: grid;
            }
            .scroll-top-btn{
                bottom: 140px !important;
                right: 20px !important;
                gap: 4px;  
                img{
                    width: 20px !important;
                    height: 20px !important;
                }
            }
        }
    }

    @media(min-width: 1600px) {
        .login-body {
            .each-ins {
                .img-wrap .img-span {
                    min-height: 386px;
                }

                .step-1 img {
                    width: 285px;
                    height: 367px;
                }

                .step-2-b img {
                    width: 285px;
                    height: 370px;
                }

                .step-2-a img {
                    width: 289px;
                    height: 258px;
                }

                .step-2-c-1 img {
                    width: 288px;
                    height: 355px;
                }

                .step-3 img {
                    width: 260px;
                    height: 388px;
                }

                .step-2-c-2 img {
                    width: 288px;
                    height: 355px;
                }
            }
        }

    }
}

.pwl-main {
    .pwl-body {
        margin-top: 80px;

        .pwl-head {
            background: linear-gradient(180deg, #e1e9f3 -19.67%, #FBFCFE 50%);
            padding: 3.5rem 0;

            h1 {
                text-align: center;
                // padding-left: 1rem;
                margin-bottom: 0;
                font-weight: com.$font-weight1;
                padding-bottom: 0.5rem;
            }
        }

        .pwl-content {
            .main-h-2 {
                font-weight: com.$font-weightBold;
                color: com.$blue-1;
            }

            .sub-head,
            .text-p,
            .lo-text {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .text-p {
                font-size: 14px;
                color: com.$black-2;
                padding: 1rem 0;

                p {
                    max-width: 41%;
                    margin-bottom: 0;
                }
            }

            .lo-text {
                .timer {
                    font-weight: com.$font-weightBold;
                    color: com.$black-2;

                    .blue {
                        color: com.$blue-1;
                    }
                }
            }
        }
    }

}

@media (max-width: 500px) {
    .pwl-main {
        .pwl-body {
            margin-top: 130px; // as height of header is 150px at width less than 500px
        }
    }
}