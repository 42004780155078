.modal-wrapper {
        color: #666;
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        max-width: 398px;
    
        .modal-heading{
            font-size: 14px; 
            // padding: 8px 10px; 
            font-weight: 500; 
            border: none;
            color: #fff;
            background-color: #1CABE3;
            padding: 0.5rem 1.4rem;
            border-radius: 0;             
        }
    
        .modal-content {
            background-color: #fff;
            min-height: 180px;
            border: 1px solid #ccc;
            border-radius: 0;
        }
        .row {
            margin-right: 0;
            margin-left: 0;
        }
        .contact-form-wrapper {
            min-height: 100px;
            padding: 16px 10px 0;

            .a-f-m {
                font-size: 12px;
            }
    
            .form-label {
                font-weight: 400;
                font-size: 14px;
                color: #666;
            }
    
            .form-input {
                input,
                textarea {
                    padding: 6px 8px;
                    min-height: 38px;
                    width: 100%;
                    border-radius: 4px;
                    border: 1px solid #ddd;
                }
                textarea{
                    resize: none;
                }
                input::placeholder,
                textarea::placeholder {
                    font-size: 12px;
                    color: #ccc;
                }
    
                input:focus-visible,
                textarea:focus-visible,
                input:focus,
                textarea:focus,
                input:active,
                textarea:active {
                    border: 1px solid #2684FF;
                    outline-color: #2684FF;
                }
            }
            .color-red {
                color: #AF0000;
            }           
        }
    
        .modal-footer {
            border-top: 0;
            padding: 10px 10px 24px;
    
            .btn-row {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
                .btn {
                    padding-left: 20px;
                    padding-right: 20px;
                    border-radius: 5px;
                    font-weight: 400;
                    font-size: 14px;
                }
                .btn-secondary {
                    color: #333;
                }
            }
        } 
    
        .success-wrapper{
            padding: 20px;
        }
        .close-btn{
            display: flex;
            justify-content: end;
            cursor: pointer;
        .cross{
            width:  14px;
            height: 14px;
        } 
        }
        .success-box{
            text-align: center;
            margin: 10px 0;
            transform: translateY(-15px);
            .tick{
                width: 35px;
                height: 35px;
            }
        }
        .success-msg{
            text-align: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #666666;
            margin-bottom: 16px;
            .id-msg {
                font-weight: 500;
            }
        }
}

.contact-ext-modal.modal-wrapper{
    padding-top: 80px;
}

@media (max-width: 767px) {
    .contact-ext-modal.modal-wrapper {
        padding-top: 60px;
        max-width: calc(100% - 36px);
        margin: auto;
    }
}