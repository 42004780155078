@use './utils/styles/publicStyleVariables' as com;

.abt-malena-main {
    font-family: com.$font-family1;
    font-style: com.$font-style1;
    font-weight: com.$font-weight1;
    background-color: com.$white-1;
    color: com.$black-2;
    // height: auto;
    height: 100%;
    min-height: 100vh;
    // overflow-x: hidden;
    position: relative;

    .main-h-1 {
        font-size: 24px;
    }

    .main-h-2 {
        font-size: 20px;
    }

    .main-h-3 {
        font-size: 16px;
    }

    .sub-h-3 {
        font-size: 14px;
    }

    .row {
        margin-left: 0;
        margin-right: 0;
    }

        @media (max-width: 767px) { // all devices with lesser width then or equal 767px, mobiles
            .main-h-1 {
                font-size: 16px !important;
            }
    
            .main-h-2 {
                font-size: 16px !important;
            }
    
            .sub-h-3 {
                font-size: 12px !important;
            }
        }
    
        @media (max-width: 1024px) and (min-width:768px) { // all tab devices within this range, inclusive 768px
            .main-h-1 {
                font-size: 20px !important;
            }
    
            .main-h-2 {
                font-size: 20px !important;
            }
    
            .sub-h-3 {
                font-size: 14px !important;
            }
    
        }

    &.public {
        .abt-mal-body, .abt-card-body {
            margin-top: 80px;
            // padding-bottom: 320px;
        }
    }

    .abt-mal-body {        

        // padding: 0 1rem;
        position: relative;
        z-index: 1;
        background-color: com.$white-1;
        .mal-glance {
            position: relative;
            display: flex;
            min-height: 360px;
            height: 360px;
            padding: 8px 1rem;
            padding-bottom: 0;

            .glance-text {
                max-width: 35%;
                padding-left: 2rem;
                align-items: center;
                z-index: 1;

                .main-h-2 {
                    color: com.$blue-1;
                    font-weight: com.$font-weightBold;
                    margin-bottom: 1.5rem;
                }
            }

            .glance-img {
                display: block;
                position: absolute;
                // max-height: 420px;
                height: 100%;
                right: 0;
                top: 0;
                min-width: 1020px;
                background-size: cover !important;
                background-position: bottom !important;
            }
            .glance-img-2 {
                display: none;
            }

            .glance-lap-img {
                position: absolute;
                right: 44px;
                bottom: -68px;
                height: 425px;
                width: 497px;
                background-size: cover !important;
                background-position: bottom !important;
            }
        }

        .esg-analysis {
            position: relative;

            .head {
                .main-h-2 {
                    font-weight: com.$font-weightBold;
                    color: com.$white-1;
                    margin-bottom: 1rem;
                    padding-left: 1.5rem;
                    margin-top: 0.5rem;
                }
            }

            .ml-a {
                background-color: com.$blue-1;
                padding: 1rem;
                padding-left: 2.5rem;
                flex: 0 0 54%;
                max-width: 54%;

                .img-div {
                    color: com.$blue-1;
                    padding-left: 1rem;

                    .wrap {
                        position: relative;
                        width: 268px;
                        height: 108px;

                        .arr-img-1 {
                            position: absolute;
                            top: -43px;
                            left: 174px;
                        }

                        .arr-img-2 {
                            position: absolute;
                            left: 242px;
                            top: -43px;
                        }

                        .arr-img-3 {
                            position: absolute;
                            left: 268px;
                            bottom: 36px;
                        }

                        .img-2 {
                            position: absolute;
                            top: -143px;
                            left: 302px;
                        }

                        .img-4 {
                            position: absolute;
                            left: 314px;
                            top: -2px;
                        }
                    }

                    .each-card {

                        background-color: com.$white-1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        border-radius: 8px;
                        border: 1px solid #CBD4D9;
                        box-shadow: 0 3px 3px 0px rgba(69, 91, 99, 0.09);

                        &.img-1 {
                            width: 180px;
                            height: 100px;
                            margin-left: 98px;
                        }

                        &.img-2 {
                            width: 180px;
                            height: 100px;
                            // 
                            // padding-right: 45px;
                        }

                        &.img-3 {
                            width: 268px;
                            height: 108px;
                            margin-top: 43px;
                        }

                        &.img-4 {
                            width: 289px;
                            height: 110px;
                        }

                        .title {
                            padding-bottom: 8px;
                        }
                    }
                }
            }

            .esg-a {
                flex: 0 0 46%;
                max-width: 46%;
                padding: 1rem;
                background-color: com.$blue-3;

                .img-div {
                    padding: 1rem;

                    .each-info {
                        display: inline-flex;

                        .icon {
                            vertical-align: middle;
                            align-self: center;
                        }

                        .info {
                            display: block;
                            color: com.$white-1;
                            padding-left: 8px;

                            .i-num {
                                font-size: 24px;
                            }

                            .i-lab {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }

        .click-cards {
            background-color: com.$gray-3;
            padding: 2rem 2rem;
            

            .head {
                padding-left: 1rem;
                padding-bottom: 1rem;

                .main-h-2 {
                    font-weight: com.$font-weightBold;
                    color: com.$blue-1;
                    margin-bottom: 0;
                    padding-left: 1rem;
                }
            }

            .cards-row {
                padding-left: 1rem;                

                .cmn-card {
                    padding-right: 1rem;
                }

                .each-click-card {
                    cursor: pointer;
                    padding: 1rem;
                    width: 100%;
                    border: 1px solid com.$gray-4;
                    border-radius: 8px;
                    background-color: com.$white-1;
                    box-shadow: 0 3px 3px 0px rgba(69, 91, 99, 0.09);
                    display: flex;
                    flex-direction: column;
                    // min-height: 190px;
                    height: 100%;

                    &:hover {
                        border: 1px solid com.$primary-blue;
                        box-shadow: 0 0 4px 0 #00abe342;
                    }

                    .title {
                        display: inline-flex;
                        // flex: auto;
                        color: com.$blue-1;
                        ;
                        font-size: 16px;
                        padding-bottom: 1rem;

                        .title-text {

                            padding-left: 0.5rem;
                            width: calc(100% - 43px);
                            align-self: center;

                        }
                    }

                    .card-para {
                        flex: auto;
                        color: com.$black-2;
                        font-size: 14px;
                        p {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .abt-card-body {
        // margin-bottom: 10rem;
        padding: 0.5rem 3rem 1rem 3rem;
        z-index: 1;
        position: relative;
        background-color: com.$white-1;
        .hr-line {
            border-top: 1px solid com.$gray-5;
            margin: 0.5rem -3rem 1rem -3rem;
        }
        .abt-bc {
            padding-left: 1rem;
            font-size: 12px;
            color: com.$black-2;
            font-weight: com.$font-weightBold;
            display: flex;
            align-items: center;
            .fa-angle-left {
                font-size: 22px;
                color: com.$black-3;
            }
            .link {
                font-size: 12px;
                color: com.$blue-2;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .all-t-wrapper {
            width: 100%;
            display: flex;
            .t-names-nav {
                width: 275px;
                height: auto;
                min-height: 90vh;
                border: 1px solid com.$gray-4;
                background-color: com.$gray-3;
                box-shadow: 0 3px 3px 0px rgba(69, 91, 99, 0.09);
                margin-bottom: 1rem;
                .nav {
                    flex-wrap: nowrap; 
                    flex-direction: column;
                }
                // max-width: 300px;
                .nav-pills {
                    
                    .small .nav-link {
                        padding-right: 3rem;
                    }
                    .nav-link {
                        font-size: 16px;
                        border-bottom: 1px solid com.$gray-4;
                        
                        border-radius: 0;
                        // padding: 1rem 2rem;
                        color: com.$blue-1;
                        display: flex;
                        align-items: center;
                        padding-left: 2rem;
                        height: 65px;
                        
                        &.active {
                            background-color: com.$blue-1;
                            font-weight: com.$font-weightBold;
                            color: com.$white-1;
                            position: relative;
                            .triangle {
                                position: absolute;
                                right: 2rem;
                                width: 0;
                                    height: 0;
                                    border-top: 5px solid transparent;
                                    border-bottom: 5px solid transparent;
                                
                                    border-left: 10px solid com.$white-1;
                            }
                                                
                        }                        
                    }
                }
            }
            .t-content {
                width: calc(100% - 275px);
                .tab-content {
                    border: none;
                    .tab-pane {
                        padding: 1rem 2rem;
                    }
                }
                .each-content {
                    .each-t-main-title {
                        .main-h-3 {
                            border-bottom: 0;
                            color: com.$blue-1;
                            font-weight: com.$font-weightBold;
                            padding: 0.5rem 0;
                        }
                    }
                    .each-t-content {
                        color: com.$black-2;
                        font-size: 14px;
                        .each-t-title {
                            font-weight: com.$font-weightBold;
                            color: com.$blue-1;
                            padding-bottom: 0.5rem;
                        }
                        .each-t-para {
                            p {
                                padding-bottom: 1rem;
                                margin-bottom: 0;
                            }                            
                        }
                        
                        .inp-s, .out-s {
                            padding-bottom: 1rem;
                            .title {
                                font-weight: com.$font-weightBold;
                                color: com.$black-2;
                                padding-bottom: 1rem;
                            }
                            .highlight {
                                border: 1px solid com.$gray-4;
                                padding: 1rem;
                                border-radius: 8px;
                                margin-bottom: 1rem;
                                
                                p {
                                    line-height: 24px;
                                    margin-bottom: 0;
                                    color: com.$black-2;
                                }
                                .neg {
                                    background-color: com.$negative;
                                    padding: 3px 3px;
                                    // opacity: 0.6;
                                    // color: com.$black-1;
                                }
                                .neu {
                                    background-color: com.$neutral;
                                    padding: 3px 3px;
                                    // opacity: 0.6;
                                    // color: com.$black-1;
                                }
                                .pos {
                                    background-color: com.$positive;
                                    padding: 3px 3px;
                                    // opacity: 0.6;
                                    // color: com.$black-1;
                                }
                            }
                        }
                    }
                    &.mtds {
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        .only-p {
                            width:  calc(100% - 380px);
                        }
                        
                    }
                    &.rai {
                        .each-t-content {
                            .link {
                                cursor: pointer;
                                text-decoration: underline;
                                color: com.$blue-2;
                            }
                            .doc-analysis-main {
                                margin-left: -15px;
                                margin-right: -15px;
                                .progress-chart-col {
                                    padding-bottom: 2rem;
                                    .card-content {
                                        border: 1px solid com.$gray-4;
                                        background-color: com.$white-1;
                                        border-radius: 8px;
                                        box-shadow: 0 3px 3px 0px rgba(69, 91, 99, 0.09);
                                        .chart-wrapper {
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            canvas {
                                                width: 254px !important;
                                                height: 254px !important;
                                            }
                                        }
                                        .chart-title {
                                            min-height: 40px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            color: com.$white-1;
                                            border-radius: 8px 8px 0 0;
                                        }
                                        &.MP {
                                            .chart-title {
                                                background-color: #002245;
                                            }
                                        }
                                        &.FA {
                                            .chart-title {
                                                background-color: #1CABE3;
                                            }
                                        }
                                        &.EX {
                                            .chart-title {
                                                background-color: #ED7D31;
                                            }
                                        }
                                        &.CO {
                                            .chart-title {
                                                background-color: #1BAF5D;
                                            }
                                        }
                                        &.AR {
                                            .chart-title {
                                                background-color: #E2A900;
                                            }
                                        }
                                        &.MD {
                                            .chart-title {
                                                background-color: #789D48;
                                            }
                                        }
                                    }
                                }

                            }
                           
                        }
                    }
                    &.mio {
                        .link {
                            cursor: pointer;
                            // text-decoration: underline;
                            color: com.$blue-2;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                    &.mfaq {
                        .date-title{
                            font-weight: 500;
                        }
                        .tr-links{
                            text-decoration: underline;
                            text-decoration-color: #0049C8;
                            color: #0049C8;
                            cursor: pointer;
                        }
                        .each-t-content {
                            .title{
                                display: flex;
                                align-items: center;
                                padding-top: 0.8rem;
                            }
                            p{
                                margin-bottom: 0px;
                                padding-bottom: 14px;
                            }
                            .line{
                                border-bottom: 1px solid #CCCCCC;
                            }
                            ul {
                                list-style: none;
                            }                           
                        }
                    }
                }
            }
        }
    }

    .abt-foot {
        // position: absolute;
        // bottom: 0;
        width: 100%;
    }

    @media (max-width: 767px) { // all devices with lesser width then or equal 767px, mobiles
        &.public {
            .abt-mal-body {
                margin-top: 60px;
                padding-bottom: 3rem;
            }
            .abt-card-body {
                margin-top: 60px;
                padding-bottom: 2rem;
            }
        }
        .abt-mal-body {
            
            .mal-glance {
                min-height: 250px;
                height: 250px;
                .glance-text {
                    max-width: 98%;
                    padding-left: 0;
                    align-items: start;
                    .head {
                        margin-bottom: 0.5rem;
                        font-size: 14px !important;
                    }
                    .col-12 {
                        padding: 0;
                    }
                }
                .glance-img {
                    display: block;
                    min-width: auto;
                    background-position-x: 0% !important;
                    width: 100%;
                    height: 199px;
                    top: auto;
                    bottom: 0;
                }
                .glance-lap-img {
                    width: 175px;
                    height: 150px;
                    bottom: -8px;
                    right: calc(50% - 150px);
                }
            }
            .esg-analysis {
                .ml-a {
                    padding-left: 0.5rem;
                    flex: 0 0 100%;
                    max-width: 100%;
                    .img-div {
                        width: 332px;
                        margin: auto;
                        padding-left: 0;
                        .each-card {
                            .title {
                                font-size: 12px;
                            }
                            &.img-1 {
                                width: 125px;
                                height: 89px;
                                margin-left: 38px;
                                .icon {
                                    img {
                                        width: 24px !important;
                                        height: 27.43px !important;
                                    }
                                }
                            }
                            &.img-2 {
                                width: 113px;
                                height: 89px;
                                top: -132px;
                                left: 192px;
                                .icon img {
                                    width: 65px;
                                    height: 35px;
                                }
                            }
                            &.img-3 {
                                width: 155px;
                                height: 99px;
                                position: relative;
                                z-index: 1;
                                // margin-left: 38px;
                                .title {
                                    padding-left: 1.5rem;
                                }
                                .icon {
                                    img {
                                        width: 86px !important;
                                        height: 21.5px !important;
                                    }
                                }
                            }
                            &.img-4 {
                                width: 156px;
                                height: 101px;
                                left: 168px;
                                .title {
                                    padding-left: 8px;
                                }
                                .icon img {
                                   width: 101px;
                                   height: 34px;
                                }
                            }
                            
                        }
                        .wrap {
                            width: 155px;
                            height: 99px;
                            .arr-img-1 {
                                left: 84px;
                            }
                            .arr-img-2 {
                                left: 116px
                            }
                            .arr-img-3 {
                                left: 124px;
                            }
                            
                        }
                    }
                    
                }
                .esg-a {
                    padding-left: 0.5rem;
                    flex: 0 0 100%;                    
                    max-width: 100%;
                    .img-div {                        
                        .each-info {
                            &.sen-min {
                                &.pt-3 {
                                    padding-top: 0 !important;
                                }                            
                            }
                        }
                    }                    
                }
            }
            .click-cards {
                // margin-bottom: 1rem;
                padding: 1rem 1rem;
                .head {
                    .main-h-2 {
                        padding-left: 0;
                    }
                }
                .cards-row {
                    .cmn-card {
                        padding-left: 0;
                        margin-bottom: 1rem;
                    }
    
                }
            }
        }
        .abt-card-body {
            padding: 0.5rem;
            padding-bottom: 2rem;
            .hr-line {
                display: none;
            }
            .all-t-wrapper {
                display: block;
                .int-names-nav{
                    top: 52px;
                }
                .ext-names-nav{
                    top: 60px;
                }
                .t-names-nav {
                    // flex: 0 0 100%;                    
                    // max-width: 100%;
                    height: 65px;
                    min-height: auto;
                    width: 100%;
                    border: 0;
                    background-color: com.$white-1;
                    border-top: 1px solid com.$gray-5;
                    border-bottom: 1px solid com.$gray-5;
                    box-shadow: none;
                    position: sticky;
                    margin-bottom: 0.5rem;
                    z-index: 1;
                    .nav-pills {
                        .nav-item {
                            // display: flex;
                            width: 33.33%;
                            margin: auto;
                            text-align: center;
                        }
                        .rai-2 {
                            .nav-link {
                                padding-left: 8px;
                            }
                        }
                        .small {
                            .nav-link {
                                padding-right: 0;
                            }
                        }
                        .nav-link {
                            font-size: 12px;
                            padding-left: 0;
                            padding-right: 8px;
                            border-bottom: 0;
                            justify-content: center;
                            &.active {
                                .triangle {
                                    display: none;
                                }
                            }
                            
                        }
                    }
                    .nav {
                        flex-direction: row;
                    }
                }
                .t-content {
                    // width: 100%;
                    // flex: 0 0 100%;                    
                    width: 100%;
                    .each-content {
                        .each-t-content {
                            font-size: 12px;
                            .inp-s, .out-s {
                                .title {
                                    font-size: 14px;
                                }
                            }
                        }
                        &.rai {
                            .each-t-content {
                                .doc-analysis-main {
                                   margin-left: 0;
                                   margin-right: 0;
                                    .progress-chart-col {
                                        padding-bottom: 1rem;
                                        flex: 0 0 50%;
                                        max-width: 50%;
                                        padding-left: 0.5rem;
                                        padding-right: 0.5rem;
                                        .card-content {
                                            // border: 1px solid com.$gray-4;
                                            // background-color: com.$white-1;
                                            // border-radius: 8px;
                                            // box-shadow: 0 3px 3px 0px rgba(69, 91, 99, 0.09);
                                            .chart-wrapper {
                                                canvas {
                                                    width: 164px !important;
                                                    height: 164px !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.mtds {
                            display: block;
                            .only-p {
                                width: 100%;
                            }
                            .ic img {
                                width: 100%;
                                height: calc(100% * 337/344);
                            }
                        }
                        &.mfaq {                     
                            .faq-img{
                                width: 25px !important;
                                height: 25px !important;
                            }
                        }
                    }
                    .tab-content {
                        .tab-pane {
                            padding: 1rem 0.5rem;
                        }
                    }
                }
            }
        }
        .abt-foot {
            position: relative;
            bottom: auto;
        }
    }
    @media (max-width: 1024px) and (min-width:768px) { // all tab devices within this range, inclusive 768px
       .abt-mal-body {
        .mal-glance {
            min-height: 320px;
            height: 320px;
            .glance-text {
                max-width: 50%;
                padding-left: 1rem;
                align-items: start;
                align-content: center;
                .head {
                    margin-bottom: 1rem;
                }
                .col-12 {
                    padding: 0;
                }
            }
            .glance-img-2 {
                display: block;
                position: absolute;
                background-size: cover !important;
                min-width: auto;
                background-position-x: 0% !important;
                width:80%;
                height: 100%;                
                bottom: 0;
                right: 0;
            }
            .glance-img {
                display: none;
            }
            .glance-lap-img {
                width: 313px;
                height: 269px;
                bottom: -16px;
                right: 26px;
            }
        }
        .esg-analysis {
            .head {
                .main-h-2 {
                    padding-left: 1rem;
                }
            }
            .ml-a, .esg-a {
                flex: 0 0 100%;                    
                max-width: 100%;
            }
            .ml-a {
                padding-left: 1rem;
                .img-div {
                    width: 608px;
                    margin: auto;
                }
            }
        }
        .click-cards {
            margin-bottom: 1rem;
            padding: 1rem 1rem;
            .head {
                .main-h-2 {
                    padding-left: 0;
                }
            }
            .cards-row {
                .cmn-card {
                    padding-left: 0;
                    margin-bottom: 1rem;
                }

            }
        }
       }

       .abt-card-body {
        padding: 1rem;
        padding-bottom: 2rem;
        .hr-line {
            display: none;
        }
        .all-t-wrapper {
            display: block;
            .int-names-nav{
                top: 60px;
            }
            .ext-names-nav{
                top: 80px;
            }
            .t-names-nav {
                // flex: 0 0 100%;                    
                // max-width: 100%;
                height:45px;
                min-height: auto;
                width: 100%;
                border: 0;
                background-color: com.$white-1;
                border-top: 1px solid com.$gray-5;
                border-bottom: 1px solid com.$gray-5;
                box-shadow: none;
                position: sticky;
                margin-bottom: 0.5rem;
                z-index: 1;
                .nav-pills {
                    .abt-tabs-int {
                        // display: flex;
                        width: 33.33%;
                        margin: auto;
                        text-align: center;
                    }
                    .abt-tabs-ext {
                        // display: flex;
                        width: 25%;
                        margin: auto;
                        text-align: center;
                    }
                    .rai-2 {
                        .nav-link {
                            padding-left: 8px;
                        }
                    }
                    .small {
                        .nav-link {
                            padding-right: 0;
                        }
                    }
                    .nav-link {
                        height: 45px;
                        font-size: 12px;
                        padding-left: 0;
                        border-bottom: 0;
                        justify-content: center;
                        &.active {
                            .triangle {
                                display: none;
                            }
                        }
                        
                    }
                }
                .nav {
                    flex-direction: row;
                }
            }
            .t-content {
                // width: 100%;
                // flex: 0 0 100%;                    
                width: 100%;
                .each-content {
                    .each-t-content {
                        font-size: 14px;
                        .inp-s, .out-s {
                            .title {
                                font-size: 14px;
                            }
                        }
                    }
                    &.rai {
                        .each-t-content {
                            .doc-analysis-main {
                               margin-left: 0;
                               margin-right: 0;
                                .progress-chart-col {
                                    padding-bottom: 1rem;
                                    flex: 0 0 33.333%;
                                    max-width: 33.333%;
                                    padding-left: 0.5rem;
                                    padding-right: 0.5rem;
                                    .card-content {
                                        // border: 1px solid com.$gray-4;
                                        // background-color: com.$white-1;
                                        // border-radius: 8px;
                                        // box-shadow: 0 3px 3px 0px rgba(69, 91, 99, 0.09);
                                        .chart-wrapper {
                                            canvas {
                                                width: 246px !important;
                                                height: 246px !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.mtds {
                        // display: block;
                        .only-p {
                            width: calc(100%  - 360px);
                        }
                        // .ic img {
                        //     width: 100%;
                        //     height: calc(100% * 337/344);
                        // }
                    }
                }
                .tab-content {
                    .tab-pane {
                        padding: 1rem;
                    }
                }
            }
        }
    }
        
    }

}
.private-abt-malena {
    .abt-card-body {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}