.spinner-color {
    color: #002345;
}
.process_msg{
    text-align: center;
}
.process_txt{
    font-size: 16px;
    color:  #002345;
    font-family: 'Roboto medium';
}
.process_subtxt{
    color: #002345;
    font-family: 'Roboto medium';
}
.upload_loader{
    position: relative;
    top: 30%
}
.upload_spinner{
    position: relative;
    left: 49%;
    margin-top: 15px;
    height: 1.5rem;
    width: 1.5rem;
}