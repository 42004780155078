$font-family1: Roboto;
$font-style1: normal;
$font-weight1: 400;
$font-weightBold: 500;

$primary-blue: #1CABE3;
$blue-1: #002245;
$blue-2: #0049C8;
$blue-3: #002C59;
$blue-4: #0049CB;
$blue-5: #00abe3;


$white-1: #FFF;

$black-1: #000;
$black-2: #333;
$black-3: #666;

$gray-1: #F4F4F4;
$gray-2: #EFEFEF;
$gray-3: #FAFAFA;
$gray-4: #E4E4E4;
$gray-5: #ccc;
$gray-6: #D4D4D4;

$red-1: #AF0000;
$red-2: #FF4040;

$positive: rgba(27, 175, 93, 0.6); // with 0.6 opacity
$negative: rgba(255, 64, 64, 0.6);
$neutral:  rgba(254, 208, 83, 0.6);