    @font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
   
@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-Medium.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
   
@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-MediumItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
   

   
